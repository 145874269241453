
import { defineComponent, nextTick, ref } from 'vue'
import TmChips from '@/components/shared/TmChips.vue'
import TmBadge from '@/components/shared/TmBadge.vue'

export default defineComponent({
  components: {
    TmBadge,
    TmChips,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    isFocused: {
      type: Boolean,
    },
  },
  emits: ['update:model-value', 'focus', 'blur'],
  setup(props, context) {
    const fieldRef = ref()
    const tmChips = ref()

    const blur = () => {
      fieldRef.value.blur()
    }
    const focus = () => {
      fieldRef.value.focus()
    }

    const removeItem = (e: any) => {
      const val = [...props.modelValue].filter((el: any) => el !== e)
      context.emit('update:model-value', val)

      if (!tmChips.value) { return }
      nextTick(() => {
        tmChips.value.updateVisibleChildren()
      })
    }

    return {
      tmChips,
      fieldRef,
      blur,
      focus,
      removeItem,
    }
  },
})
