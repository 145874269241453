import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62a1c9ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-fields-container-tags" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_chips = _resolveComponent("tm-chips")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_field, _mergeProps({
      ref: "fieldRef",
      type: "tags",
      "model-value": _ctx.modelValue,
      transparent: "",
      "sidebar-paddings": "",
      size: "small",
      "hide-input": "",
      "one-line": ""
    }, _ctx.$attrs, {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', $event))),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus'))),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur')))
    }), _createSlots({
      option: _withCtx(({ opt }) => [
        _createVNode(_component_tm_badge, { class: "align-self-center" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(opt), 1)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 2
    }, [
      (!_ctx.isFocused && _ctx.modelValue.length)
        ? {
            name: "selected",
            fn: _withCtx(() => [
              _createVNode(_component_tm_chips, {
                ref: "tmChips",
                class: "my-n2px",
                items: _ctx.modelValue,
                size: "large",
                "toggle-badge-size": "large"
              }, null, 8, ["items"])
            ])
          }
        : undefined
    ]), 1040, ["model-value"])
  ]))
}