
import { defineComponent, nextTick, ref, watch } from 'vue'

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Add name',
    },
    isFocused: {
      type: Boolean,
    },
  },
  emits: ['focus', 'update:modelValue'],
  setup(props, { emit }) {
    const fieldRef = ref()

    const firstName = ref('')
    const lastName = ref('')

    const setFieldsValue = () => {
      firstName.value = props.modelValue?.split(' ')[0] || ''
      lastName.value = props.modelValue?.split(' ')[1] || ''
    }

    const updateModelValue = () => {
      emit('update:modelValue', (firstName.value.trim() + ' ' + lastName.value.trim()).trim())
    }

    const blur = () => {
      fieldRef.value.blur()
    }
    const focus = () => {
      fieldRef.value.focus()
    }

    watch(
      () => props.isFocused,
      (newValue) => {
        if (newValue) {
          setFieldsValue()
          nextTick(focus)
        } else {
          updateModelValue()
        }
      })

    return {
      fieldRef,
      firstName,
      lastName,
      blur,
      focus,
    }
  },
})
