
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';

export default defineComponent({
  components: {
    TmTooltip,
    TmButton,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    isFocused: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
  emits: ['update:model-value'],
  setup(props) {
    const copiedStatus = ref(false)
    const copyText = () => {
      copiedStatus.value = true
      copyToClipboard(props.modelValue)
    }

    return {
      copyText,
      copiedStatus,
    }
  },
})
