
import { defineComponent, nextTick, ref, watch } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: {
    TmTooltip,
    TmButton,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Add WhatsApp',
    },
    isFocused: {
      type: Boolean,
    },
  },
  emits: ['focus', 'update:modelValue'],
  setup(props, { emit }) {
    const fieldRef = ref()

    const value = ref(props.modelValue)

    const setFieldsValue = () => {
      value.value = props.modelValue
    }

    const updateModelValue = () => {
      emit('update:modelValue', value.value)
    }

    const blur = () => {
      fieldRef.value.blur()
    }
    const focus = () => {
      fieldRef.value.focus()
    }

    watch(
      () => props.isFocused,
      (newValue) => {
        if (newValue) {
          setFieldsValue()
          nextTick(focus)
        } else {
          updateModelValue()
        }
      })

    return {
      fieldRef,
      value,
      blur,
      focus,
    }
  },
})
