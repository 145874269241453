
import type { PropType } from 'vue'
import { defineComponent, ref, getCurrentInstance, onUnmounted, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import Highlight from '@/components/shared/Highlight.vue'
import { useFieldsContainer } from '@/compositions/fieldsContainer'

// custom containers
import TmFieldsContainerTextarea from '@/components/shared/tmFieldsContainer/TmFieldsContainerTextarea.vue'
import TmFieldsContainerEmail from '@/components/shared/tmFieldsContainer/TmFieldsContainerEmail.vue'
import TmFieldsContainerPhone from '@/components/shared/tmFieldsContainer/TmFieldsContainerPhone.vue'
import TmFieldsContainerSocialMedia from '@/components/shared/tmFieldsContainer/TmFieldsContainerSocialMedia.vue'
import TmFieldsContainerDomain from '@/components/shared/tmFieldsContainer/TmFieldsContainerDomain.vue'
import TmFieldsContainerDomains from '@/components/shared/tmFieldsContainer/TmFieldsContainerDomains.vue'
import TmFieldsContainerFullName from '@/components/shared/tmFieldsContainer/TmFieldsContainerFullName.vue'
import TmFieldsContainerCopy from '@/components/shared/tmFieldsContainer/TmFieldsContainerCopy.vue'
import TmFieldsContainerWhatsapp from '@/components/shared/tmFieldsContainer/TmFieldsContainerWhatsapp.vue'
import TmFieldsContainerTags from '@/components/shared/tmFieldsContainer/TmFieldContainerTags.vue'

type FieldTypes =
  'text' |
  'textarea' |
  'select' |
  'selectStatus' |
  'selectPriority' |
  'assignee' |
  'selectOrganization' |
  'country' |
  'selectTimezone' |
  'email' |
  'phone' |
  'socialMedia' |
  'domain' |
  'domains' |
  'fullName' |
  'followers' |
  'tags' |
  'tagsList' |
  'primaryContact' |
  'dueDate' |
  'number' |
  'associate' |
  'carbonCopy' |
  'copy' |
  'whatsapp'

type CustomContainersType = {
  [key in FieldTypes]?: any
}

export default defineComponent({
  components: {
    Highlight,
    TmButton,
    TmFieldsContainerTextarea,
    TmFieldsContainerPhone,
    TmFieldsContainerSocialMedia,
    TmFieldsContainerFullName,
    TmFieldsContainerTags,
    TmFieldsContainerCopy,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<FieldTypes>,
      default: 'text',
    },
    leftLabel: {
      type: Boolean,
      default: true,
    },
    inputContainerClass: {
      type: String,
    },
    search: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const fieldRef = ref()
    const modelValue = ref('')
    const isFocused = ref(false)
    const uid = getCurrentInstance()?.uid
    const { getActiveContainer, setActiveContainer, oneEditableFieldNumber } = useFieldsContainer()

    const overflowAuto = computed(() => !['phone', 'socialMedia', 'email'].includes(props.type!))
    // show 'Cancel' & 'Save' buttons for the next fields
    const fieldsWithButtons = ref<FieldTypes[]>([
      'text',
      'textarea',
      'socialMedia',
      'email',
      'phone',
      'domain',
      'domains',
      'fullName',
      'carbonCopy',
      'followers',
      'tags',
      'tagsList',
      'number',
      'dueDate',
      'associate',
      'whatsapp',
    ])
    const showButtons = computed(() => fieldsWithButtons.value.includes(props.type!))

    const customContainers: CustomContainersType = {
      textarea: TmFieldsContainerTextarea,
      email: TmFieldsContainerEmail,
      phone: TmFieldsContainerPhone,
      socialMedia: TmFieldsContainerSocialMedia,
      domain: TmFieldsContainerDomain,
      domains: TmFieldsContainerDomains,
      fullName: TmFieldsContainerFullName,
      copy: TmFieldsContainerCopy,
      tags: TmFieldsContainerTags,
      whatsapp: TmFieldsContainerWhatsapp,
    }
    const currentCustomContainer = computed(() => customContainers[props.type!])
    // make field readonly if another container with buttons is focused
    const readonly = computed(() => !!(oneEditableFieldNumber.value && uid !== oneEditableFieldNumber.value))

    // props for default fields containers
    const fieldsProps = {
      select: { clearable: false },
      selectStatus: { clearable: false },
      selectPriority: { clearable: false },
      followers: { fieldType: 'carbonCopy', placeholder: 'Add followers', showPreview: true, hideOptionDescription: true },
      tags: { placeholder: 'Add tags', linesCount: 2 },
      tagsList: { linesCount: 2 },
      dueDate: { hideReminder: true, alwaysFocus: true },
      associate: { linesCount: 2 },
    }

    const setFirstFocus = () => {
      // DO NOT update with NextTick(), not working in this case
      setTimeout(() => {
        if (!getActiveContainer()) {
          setActiveContainer(uid, showButtons.value)
          isFocused.value = true
        }
      }, 0)
    }
    // stay focused if buttons are shown
    const stayFocused = () => {
      if (isFocused.value) {
        showButtons.value ? fieldRef.value.focus?.() : removeFocus()
      }
    }
    const removeFocus = () => {
      setActiveContainer(undefined)
      isFocused.value = false
      fieldRef.value.blur?.()
    }

    onUnmounted(() => {
      if (getActiveContainer() === uid) {
        setActiveContainer(undefined)
      }
    })

    return {
      fieldRef,
      modelValue,
      isFocused,
      overflowAuto,
      currentCustomContainer,
      fieldsProps,
      showButtons,
      setFirstFocus,
      stayFocused,
      removeFocus,
      readonly,
    }
  },
})
