import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a623bab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-fields-container-full-name" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isFocused)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_tm_field, {
            ref: "fieldRef",
            modelValue: _ctx.firstName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstName) = $event)),
            placeholder: "First name",
            "sidebar-paddings": "",
            size: "small",
            class: "mr-1"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_tm_field, {
            modelValue: _ctx.lastName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastName) = $event)),
            placeholder: "Last name",
            "sidebar-paddings": "",
            size: "small"
          }, null, 8, ["modelValue"])
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["tm-fields-container-full-name__value", {
        'tm-fields-container-full-name__value--placeholder': !_ctx.modelValue
      }]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focus')))
        }, _toDisplayString(_ctx.modelValue || _ctx.placeholder), 3))
  ]))
}