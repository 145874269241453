
import { defineComponent, nextTick, ref, watch } from 'vue'

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    isFocused: {
      type: Boolean,
    },
  },
  emits: ['focus'],
  setup(props) {
    const fieldRef = ref()

    const blur = () => {
      fieldRef.value.blur()
    }
    const focus = () => {
      fieldRef.value.focus()
    }

    watch(
      () => props.isFocused,
      async (newValue) => {
        if (newValue) {
          await nextTick()
          fieldRef.value.adjustTextareaHeight()
          focus()
        }
      })

    return {
      fieldRef,
      blur,
      focus,
    }
  },
})
