import type { ComputedRef } from 'vue'
import { computed, ref } from 'vue'

type ActiveFieldsContainer = {
  number: number | undefined;
  withButtons: boolean | undefined;
}
type UseFieldsContainerVal = {
  getActiveContainer: () => number | undefined;
  setActiveContainer: (activeContainerId: number | undefined, containerWithButtons?: boolean) => void;
  oneEditableFieldNumber: ComputedRef<number | undefined>;
}

const activeFieldsContainer = ref<ActiveFieldsContainer>({
  number: undefined,
  withButtons: undefined,
})

export const useFieldsContainer = ():UseFieldsContainerVal => {
  const getActiveContainer = () => activeFieldsContainer.value.number
  const setActiveContainer = (activeContainerId: number | undefined, containerWithButtons?: boolean) => {
    activeFieldsContainer.value.number = activeContainerId
    activeFieldsContainer.value.withButtons = containerWithButtons
  }
  const oneEditableFieldNumber = computed(() => {
    if (activeFieldsContainer.value.withButtons && activeFieldsContainer.value.number) return activeFieldsContainer.value.number
    return undefined
  })

  return {
    getActiveContainer,
    setActiveContainer,
    oneEditableFieldNumber,
  }
}
