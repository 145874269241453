
import type { PropType } from 'vue'
import { computed, defineComponent, ref, watch } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmFieldContainerRemoveBtn from '@/components/shared/tmFieldsContainer/TmFieldContainerRemoveBtn.vue'

const getEmptyField = () => ('')
export default defineComponent({
  components: {
    TmFieldContainerRemoveBtn,
    TmTooltip,
    TmButton,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => [getEmptyField()],
    },
    placeholder: {
      type: String,
      default: 'Add domain',
    },
    isFocused: {
      type: Boolean,
    },
    leftLabel: {
      type: Boolean,
    },
    oneDomain: {
      type: Boolean,
    },
  },
  emits: ['focus', 'update:modelValue'],
  setup(props, { emit }) {
    const errorHintRef = ref([])
    const focusedItem = ref(0)
    const isEmptyFields = computed(() => !props.isFocused && props.modelValue.length === 1 && !props.modelValue[0])

    const setFocusedItem = (index: number) => {
      focusedItem.value = index
    }
    setFocusedItem(props.modelValue.length - 1)

    const addAnother = () => {
      emit('update:modelValue', [...props.modelValue, getEmptyField()])
      setFocusedItem(props.modelValue.length)
    }

    const setFirstEmptyValue = () => {
      emit('update:modelValue', [getEmptyField()])
    }

    if (!props.modelValue.length) {
      setFirstEmptyValue()
    }

    const deleteItem = (deleteIndex: number) => {
      if (props.modelValue?.length > 1) {
        emit('update:modelValue', props.modelValue?.filter((item, index) => index !== deleteIndex))
        setFocusedItem(props.modelValue.length - 2)
      } else {
        setFirstEmptyValue()
      }
    }

    watch(
      () => props.isFocused,
      (newValue) => {
        if (newValue && !props.modelValue?.length) {
          setFirstEmptyValue()
        }
      })

    return {
      errorHintRef,
      isEmptyFields,
      focusedItem,
      addAnother,
      deleteItem,
      setFocusedItem,
    }
  },
})
