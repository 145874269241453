import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a72222a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-fields-container-textarea" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isFocused)
      ? (_openBlock(), _createBlock(_component_tm_field, _mergeProps({
          key: 0,
          ref: "fieldRef",
          "model-value": _ctx.modelValue,
          placeholder: _ctx.placeholder,
          type: "textarea",
          transparent: "",
          "sidebar-paddings": "",
          size: "xxSmall",
          resize: "",
          "auto-resize": ""
        }, _ctx.$attrs), null, 16, ["model-value", "placeholder"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "tm-fields-container-textarea__value-wrapper",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('focus')))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["tm-fields-container-textarea__value", {
          'tm-fields-container-textarea__value--placeholder': !_ctx.modelValue
        }])
          }, _toDisplayString(_ctx.modelValue || _ctx.placeholder), 3)
        ]))
  ]))
}